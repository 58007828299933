import React, { useEffect, useCallback } from "react";
import Head from "next/head";
import useSWR from "swr";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

import get from "lodash.get";
import isEmpty from "lodash.isempty";

import { HEDGING_BASE_URL } from "@/lib/regex";

import AppLayout from "@/layouts/app-layout";
import FourOFour from "@/components/four-o-four";
import SiteAuthentication from "@/components/site-authentication";
import StackLayout from "@/layouts/stack-layout";
import { HedgingProvider } from "@/context/betaContext";
import { useWindowSize } from "react-use";

function Stencil() {
  const router = useRouter();
  const shouldFetch = router.isReady;
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const path = shouldFetch
    ? typeof window !== "undefined" &&
      (window.location.port.includes("3000") ||
        window.location.hostname.includes("netlify"))
      ? `/${get(router, "query.stencil")?.join("/")}.json`
      : !router?.query?.stencil
      ? `${HEDGING_BASE_URL}/index`
      : `${HEDGING_BASE_URL}/${get(router, "query.stencil")?.join("/")}`
    : null;

  const desktop = get(useWindowSize(), "width") > 1024 ? true : false;

  const { data, error, isLoading, isValidating } = useSWR(
    shouldFetch ? path : null,
    () => fetch(path).then((res) => res.json()),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    }
  );

  // redirection after login
  useEffect(() => {
    const exists = localStorage.getItem("hedgeUrl");
    if (
      !!router?.query?.portfolio_id &&
      !get(router, "query.stencil")?.join("/")?.includes("settings") &&
      !isAuthenticated
    ) {
      if (!exists) {
        localStorage.setItem("hedgeUrl", router.asPath);
      }

      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, router]);

  useEffect(() => {
    const hedgeUrl = localStorage.getItem("hedgeUrl");
    if (hedgeUrl && isAuthenticated) {
      router.push(hedgeUrl);
      localStorage.removeItem("hedgeUrl");
    }
  }, [isAuthenticated, router]);

  // redirection after login on manage notifications
  useEffect(() => {
    const exists = localStorage.getItem("manageNotifications");
    if (router?.asPath === "/manage-notifications" && !isAuthenticated) {
      if (!exists) {
        localStorage.setItem("manageNotifications", router.asPath);
      }
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, router]);

  // redirection after login on setting
  useEffect(() => {
    const handleRedirect = () => {
      const settings = localStorage.getItem("settings");
      const stencilPath = get(router, "query.stencil")?.join("/");
      const portfolioId = get(router, "query.portfolio_id");

      if (stencilPath === "hedge-builder/settings" && !isAuthenticated) {
        if (!settings) {
          localStorage.setItem("settings", router.asPath);
          if (portfolioId) {
            localStorage.setItem("portfolio_Id", portfolioId);
          }
        }
        loginWithRedirect();
      }
    };

    // Run only on the client side
    if (typeof window !== "undefined") {
      const timer = setTimeout(handleRedirect, 200);
      return () => clearTimeout(timer);
    }
  }, [router, isAuthenticated, loginWithRedirect]);

  const portfolioId = router.query.portfolio_id;

  // update url path if match with setting and authenticated
  useEffect(() => {
    if (
      get(router, "query.stencil")?.join("/").includes("setting") &&
      isAuthenticated
    ) {
      const newUrl = `/hedge-builder?portfolio_id=${portfolioId}`;
      router.push(newUrl); // Navigates to the new URL
    }
  }, [isAuthenticated, portfolioId, router]);

  useEffect(() => {
    const NotificationUrl = localStorage.getItem("manageNotifications");
    if (NotificationUrl && isAuthenticated) {
      router.push(NotificationUrl);
      localStorage.removeItem("manageNotifications");
    }
  }, [isAuthenticated, router]);

  // store url on localstorage

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (window.location.host.includes("hedgewithetfs")) {
        const stencilPath = get(router, "query.stencil")?.join("/");

        if (
          window.location.host.includes("int") &&
          !!stencilPath &&
          !isAuthenticated
        ) {
          router.push("https://int.hedgewithetfs.com/");
        }
      }
    }, 1500); // Delay in milliseconds (1 second in this case)

    return () => clearTimeout(timeout); // Cleanup the timeout on unmount
  }, [isAuthenticated, loginWithRedirect, router]);

  // redirection after login
  if (isLoading && isEmpty(data)) return <></>;

  const {
    className,
    components,
    metaData,
    url,
    contentType,
    noMaster,
    bgDesktop,
    bgMobile,
  } = data || {};

  if (
    (data === undefined && error !== undefined) ||
    !isEmpty(data?.error) ||
    (router.asPath.includes("siteconfig") &&
      !router.asPath.includes("siteconfig.json"))
  ) {
    return <FourOFour />;
  }

  const isCookies = Cookies.get("authenticated") === "true";
  const isLocalhost = window.location.host.includes("localhost");
  const isNetlify = window.location.host.includes("netlify");

  return (
    <>
      {isAuthenticated || window.location.host.includes("localhost") ? (
        <>
          <Head>
            <meta charSet="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <meta httpEquiv="X-UA-Compatible" content="IE=10" />

            {metaData?.title && <title>{metaData.title}</title>}
            {metaData?.description && (
              <meta name="description" content={metaData.description} />
            )}
            {metaData?.keywords && (
              <meta name="keywords" content={metaData.keywords.toString()} />
            )}

            {/* Open Graph */}
            {metaData?.title && (
              <meta property="og:title" content={metaData.title} />
            )}
            {metaData?.description && (
              <meta property="og:description" content={metaData.description} />
            )}
            {url && <meta property="og:url" content={url} />}
            {metaData?.openGraph?.image && (
              <meta
                property="og:image:url"
                content={metaData.openGraph.image}
              />
            )}
            {metaData?.openGraph?.type && (
              <meta property="og:type" content={metaData.openGraph.type} />
            )}
            {contentType && <meta property="og:type" content={contentType} />}
            {metaData?.title && (
              <meta itemProp="name" content={metaData.title} />
            )}
            {metaData?.description && (
              <meta itemProp="description" content={metaData.description} />
            )}
          </Head>
          {noMaster ? (
            <HedgingProvider>
              <StackLayout components={components} />
            </HedgingProvider>
          ) : (
            <AppLayout>
              <div
                style={{
                  backgroundImage: desktop
                    ? `url(${bgDesktop?.url})`
                    : `url(${bgMobile?.url})`,
                }}
                className={clsx(
                  className,
                  "mt-4 bg-top bg-repeat bg-repeat-x ipad:mt-32"
                )}
              >
                <StackLayout components={components} />
              </div>
            </AppLayout>
          )}
        </>
      ) : null}
    </>
  );
}

export default Stencil;
